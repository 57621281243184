import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePracticeConnect } from '../../../core/utils/connectFirestore/usePracticeConnect';
import { useTherapistsConnect } from '../../../core/utils/connectFirestore/useTherapistsConnect';
import { useMainStore } from '../../../store/mainStore';

export const useHandlePracticeLinks = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [copyLinkLoading, setCopyLinkLoading] = useState(false);

  const practiceId = useParams().practiceId ?? '';
  const { practice, loadingPractice } = usePracticeConnect(practiceId);
  const practiceLink = practice?.practiceLinkInfo?.shortLink ?? practice?.practiceLinkInfo?.fullLink ?? '';
  const practiceQrCode = practice?.practiceLinkInfo?.qrCodeURL ?? '';
  const { activeTherapists: therapists, loadingTherapists } = useTherapistsConnect(practiceId);

  const copyLink = () => {
    try {
      if (!practiceId) {
        throw new Error('No practice id found');
      }

      setCopyLinkLoading(true);
      setTimeout(() => {
        setCopyLinkLoading(false);
      }, 1000);
      navigator.clipboard.writeText(practiceLink);
      setEventIndicator('success', 'Link kopiert');
    } catch (error) {
      console.error('Error copying link: ', error);
      setEventIndicator('error', 'Fehler beim Kopieren des Links');
    }
  };

  const openLink = () => {
    window.open(practiceLink, '_blank');
  };

  return {
    practiceLink,
    practiceQrCode,
    openLink,
    copyLink,
    copyLinkLoading,
    therapists: therapists ?? [],
    loadingPractice,
    loadingTherapists,
  };
};
