import { useState } from 'react';
import styled from 'styled-components';
import { Switch } from '../../../core/components/buttons/SwitchButton';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

type Props = {
  value: string;
  setValue: (value: string) => void;
  label: string;
};

export const AppBrandingOpeningHoursDay = ({ value, setValue, label }: Props) => {
  const [otherValue, setOtherValue] = useState<string>();
  const handleSwitch = () => {
    const newValue = otherValue ?? (value === 'Geschlossen' ? '08:00 - 18:00' : 'Geschlossen');
    setOtherValue(value);
    setValue(newValue);
  };
  const active = value !== 'Geschlossen';
  return (
    <Container>
      <Text $active={active}>{label}</Text>
      <SingleTextInput
        value={value}
        onChange={(value) => setValue(value)}
        placeholder="Montag"
        disabled={!active}
        withoutError
      />
      <Switch isChecked={active} setIsChecked={handleSwitch} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
`;

const Text = styled(H3)<{ $active: boolean }>`
  min-width: 100px;
  color: ${({ $active }) => ($active ? colors.Medium : hexWithOpacity(colors.Medium, 0.6))};
`;
