import styled from 'styled-components';
import { GenericSvg } from '../../assets/svgs/GenericSVG';
import { IconButton } from '../../core/components/buttons/IconButton';
import { handleLogout } from '../../core/utils/handleLogout';
import { useWindowDimensions } from '../../core/utils/useWindowDimensions';
import { useMainStore } from '../../store/mainStore';
import { getIsAuthenticated, getIsMitarbeiter } from '../../store/userSlice';
import { DESKTOP_BREAKPOINT_NUMBER } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { H3 } from '../../styles/textStyles';

type Props = {
  toggleDrawer?: () => void;
  drawerOpen?: boolean;
};

export const Header = ({ toggleDrawer, drawerOpen }: Props) => {
  const isInApp = useMainStore(getIsAuthenticated);
  const isMitarbeiter = useMainStore(getIsMitarbeiter);
  const { width } = useWindowDimensions();
  const isMobile = width < DESKTOP_BREAKPOINT_NUMBER;
  const isDevelopment = process.env.NODE_ENV === 'development';
  const location = window.location.href;
  const isAdmin = location.includes('admin') || isMitarbeiter;
  return (
    <Container>
      <LeftContainer>
        <GenericSvg name={'LogoWide'} height={24} primaryColor={isAdmin ? 'red' : colors.Dark} />
        {isDevelopment && <DevText>{isMobile ? '(Dev)' : '(Development)'}</DevText>}
      </LeftContainer>
      <LeftContainer>
        {isInApp && (
          <IconButton
            iconName={'Logout'}
            size={24}
            stroke={isMitarbeiter ? 'red' : colors.Dark}
            onClick={handleLogout}
          />
        )}
        {toggleDrawer && isMobile && (
          <BurgerMenu
            name={drawerOpen ? 'Close' : 'Burger'}
            height={24}
            primaryColor={isMitarbeiter ? 'red' : colors.Dark}
            onClick={toggleDrawer}
          />
        )}
      </LeftContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 20px;
  background-color: ${colors.Background};
  border-bottom: 1px solid ${colors.Light};
  gap: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 101;
`;

const BurgerMenu = styled(GenericSvg)``;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  /* gap: 20px; */
`;

const DevText = styled(H3)`
  margin-left: 10px;
  color: ${colors.Dark};
`;
