import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4 } from '../../../../styles/textStyles';
import { Practice } from '../../../../types/Practices/PracticeType';
import { useAdminChainConnect } from '../../connectUtils/useAdminChainConnect';
import { usePracticePackageConnect } from '../../connectUtils/useAdminPracticePackageConnect';

export const AdminPracticeStatusLine = ({ practice }: { practice: Practice }) => {
  const [loading, setLoading] = useState(false);
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const practicePackageInfo = usePracticePackageConnect(practice.id);
  const chain = useAdminChainConnect(practice.chainId);
  const starterPackage = practicePackageInfo?.starterPackage;
  const practiceDomain =
    import.meta.env.VITE_ENV === 'production'
      ? 'https://app.physiofit.app'
      : 'https://io-physiofit-development.web.app';
  const practiceUrl = `${practiceDomain}/newPatient?pId=${practice.id}`;
  if (practice.id === 'rec7D20cnVan50on4') {
    console.log(JSON.stringify(practice));
    console.log(JSON.stringify(practicePackageInfo));
  }
  const openLink = () => {
    window.open(practiceUrl, '_blank');
  };
  const copyLink = () => {
    navigator.clipboard.writeText(practiceUrl);
    setEventIndicator('success', 'Link kopiert');
  };
  const trackPackage = () => {
    window.open(
      'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' + starterPackage?.shipmentId,
      '_blank',
    );
  };
  const downloadDHLLabel = () => {
    window.open(starterPackage?.labelUrl, '_blank');
  };
  const downloadQRCode = () => {
    window.open(practice.practiceLinkInfo?.qrCodeURL, '_blank');
  };
  const loginAsPractice = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'createImpersonateUserToken');
      const result = (await call({ userId: chain?.ownerId })) as any;
      const token = result?.data?.token;
      signInWithCustomToken(getAuth(), token);
    } catch (error: any) {
      setEventIndicator('error', 'Fehler ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendInfoMail = async (type: 'send' | 'arrived') => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'sendPracticePackageInfoMail');
      const result = (await call({ type, practiceId: practice.id })) as any;
      if (result?.data?.status === 'success') {
        setEventIndicator('success', 'Mail gesendet');
      } else {
        console.log(result);
        setEventIndicator('error', 'Fehler ' + result?.data?.message);
      }
    } catch (error: any) {
      console.log(error);
      setEventIndicator('error', 'Fehler ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  const openFirestorePractice = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2Fpractices~2F${practice.id}`,
      '_blank',
    );
  };

  const openFirestoreOwner = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2FpracticeUsers~2F${chain?.ownerId}`,
      '_blank',
    );
  };

  const openFirestoreChain = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2FpracticeChains~2F${practice.chainId}`,
      '_blank',
    );
  };

  let actions = [
    { label: 'Link kopieren', onClick: copyLink },
    { label: 'Link öffnen', onClick: openLink },
    { label: 'Paket verfolgen', onClick: trackPackage, disabled: !starterPackage?.shipmentId },
    {
      label: 'DHL Label downloaden',
      onClick: downloadDHLLabel,
      disabled: !starterPackage?.labelUrl,
    },
    { label: 'QR Code downloaden', onClick: downloadQRCode, disabled: !practice.practiceLinkInfo?.qrCodeURL },
    { label: 'Als Praxis einloggen', onClick: loginAsPractice, disabled: !chain?.ownerId },
    { label: 'Praxis Firestore', onClick: openFirestorePractice },
    { label: 'Owner Firestore', onClick: openFirestoreOwner },
    { label: 'Chain Firestore', onClick: openFirestoreChain },
  ];
  if (!starterPackage?.sendInfoMailDate) {
    actions.push({
      label: 'Paket versendet',
      onClick: () => sendInfoMail('send'),
      disabled: !starterPackage?.shipmentId,
    });
  }
  if (!starterPackage?.arrivedInfoMailDate && starterPackage?.sendInfoMailDate) {
    actions.push({
      label: 'Paket angekommen',
      onClick: () => sendInfoMail('arrived'),
      disabled: !starterPackage?.shipmentId,
    });
  }

  return (
    <Container>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Date>{practice.createdAt?.split('T')?.[0]}</Date>
          <Name>{practice.name}</Name>
          <Date>{starterPackage?.shipmentId ? '✅' : ' '}</Date>
          <Date>{starterPackage?.sendInfoMailDate ? '✅' : ' '}</Date>
          <Date>{starterPackage?.arrivedInfoMailDate ? '✅' : ' '}</Date>

          <ActionMenu actions={actions} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${colors.Light};
  align-items: center;
  min-height: 50px;
`;

const Date = styled(H4)`
  flex: 1;
  text-align: left;
`;

const Name = styled(H4)`
  flex: 2;
  text-align: left;
`;
