import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Body, H2, H3 } from '../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../onboarding/utils/useOnboardingPracticeInfo';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { PracticeLinkContainer } from './components/PracticeLinkContainer';
import { TherapistsLinksContainer } from './components/TherapistsLinksContainer';
import { useHandlePracticeLinks } from './utils/useHandlePracticeLinks';

export const PracticeSettingsLinksScreen = () => {
  const { practiceName, practiceId } = useOnboardingPracticeInfo();
  const { practiceQrCode, practiceLink, copyLink, openLink, therapists, loadingPractice, loadingTherapists } =
    useHandlePracticeLinks();

  return (
    <MainScreenContainer>
      <Container>
        <StyledH2>Wichtige Links für den Standort {practiceName}</StyledH2>
        <HeadlineContainer>
          <StyledH3>Praxislink Standort {practiceName}</StyledH3>
          <StyledBody>
            Der Praxislink dient als allgemeiner Zugang zu Physiofit. Mit diesem Link hast du Zugriff auf alle Patienten
            und kannst auswählen über welchen Therapeuten eingeladen werden soll. So können beispielsweise auch durch
            den Empfang Patienten-Einladungen versendet werden.
          </StyledBody>
        </HeadlineContainer>
        <PracticeLinkContainer
          loadingPractice={loadingPractice}
          practiceQrCode={practiceQrCode}
          practiceLink={practiceLink}
          copyLink={copyLink}
          openLink={openLink}
        />
        <TherapistsLinksContainer
          therapists={therapists}
          loadingTherapists={loadingTherapists}
          practiceId={practiceId}
        />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 720px;
  padding-bottom: 200px;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
`;

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  padding-bottom: 15px;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;
