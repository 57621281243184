import {} from 'prettier';
import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import {
  getChainBoughtBrandingPackage,
  getOnboardingFlags,
  getPracticesBrandingCompleted,
} from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H1, H4 } from '../../../styles/textStyles';
import { AppBrandingStartButtons } from '../components/AppBrandingStartButtons';
import { ComingSoonCard } from '../components/ComingSoonCard';
import { DHLTrackingButtons } from '../components/DHLTrackingButtons';
import { MainScreenContainer } from '../components/MainScreenComponent';
import { StepCard } from '../components/StepCard';
import { useGetAddTherapistsCardsData } from '../utils/useGetAddTherapistsCardsData';

export const OverviewScreen = () => {
  const navigate = useNavigate();
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const clickedSocialMediaPage = onboardingFlags?.clickedSocialMediaPage;
  const clickedDownloadWebappPage = onboardingFlags?.clickedDownloadWebappPage;
  const clickedShowIntercom = onboardingFlags?.clickedShowIntercom;
  const addTherapistsElements = useGetAddTherapistsCardsData();
  const appBrandingOnboardingCompleted = useMainStore(getPracticesBrandingCompleted);
  const boughtBranding = useMainStore(getChainBoughtBrandingPackage);

  const { show } = useIntercom();
  const openSocialMediaPage = () => {
    window.open('https://www.physiofit.app/downloads', '_blank');
    setTimeout(() => {
      setChainOnboardingFlag('clickedSocialMediaPage');
    }, 1000);
  };

  const openDownloadWebAppPage = () => {
    navigate('../webapp-installation-instruction');
    setTimeout(() => {
      setChainOnboardingFlag('clickedDownloadWebappPage');
    }, 1000);
  };

  const toggleIntercom = () => {
    show();
    setTimeout(() => {
      setChainOnboardingFlag('clickedShowIntercom');
    }, 1000);
  };

  const addLocation = () => {
    navigate('/new-practice/practices-names');
  };

  const elements: Array<ComponentProps<typeof StepCard>> = [
    {
      active: true,
      title: 'Onboarding abgeschlossen',
      content: 'Super gemacht! Alle deine angegebenen Daten sind bei uns eingegangen.',
      mainButton: {
        title: 'Standort hinzufügen',
        onClick: addLocation,
        label: 'Du kannst jederzeit weitere Physiofit Standorte hinzufügen.',
      },
      variant: 'button',
    },
    {
      active: true,
      title: 'Starter Paket versendet',
      content:
        'Mit dem Abschluss deines Onboardings erhält jeder deiner Standorte ein Starter Paket. Die Materialien im Starter Paket unterstützen dich und dein Team, Physiofit optimal in den Praxisalltag zu integrieren und deine Patientinnen und Patienten von den Vorteilen der Physiofit App zu überzeugen.',
      variant: 'custom',
      bottomComponent: <DHLTrackingButtons />,
    },
    ...(boughtBranding
      ? [
          {
            active: appBrandingOnboardingCompleted,
            title: 'App Branding',
            content: 'Passe jetzt das Design und die Inhalte der Physiofit App für deine Patienten an.',
            variant: 'custom' as 'custom',
            bottomComponent: <AppBrandingStartButtons completed={appBrandingOnboardingCompleted} />,
          },
        ]
      : []),
    {
      title: 'Dein Physiofit Social Media Paket für deine Praxis Community',
      active: clickedSocialMediaPage,
      content:
        'Als Physiofit Partner erreichst du nicht nur mehr Zusatzeinkommen für deine Praxis, sondern vor Allem auch bessere Therapieergebnisse für deine Patientinnen und Patienten.Und um diese großartigen News zu teilen und deine Patientinnen und Patienten über Physiofit zu informieren haben wir ein exklusives Social Media Paket für dich zusammengestellt.',
      mainButton: {
        title: 'Dein Physiofit Social Media Paket',
        onClick: openSocialMediaPage,
      },
      variant: 'button',
    },
    {
      title: 'Euer Direktzugang zur Physiofit Therapeuten App',
      active: clickedDownloadWebappPage,
      content:
        'Speichert die Physiofit Therapeuten App auf euren mobilen Geräten in eurer Praxis. Wie das geht? Ganz einfach – folge einfach unserer Anleitung.',
      mainButton: {
        title: 'Zur Anleitung',
        onClick: openDownloadWebAppPage,
      },
      variant: 'button',
    },
    {
      title: 'Dein persönlicher Support und Hilfebereich ist nur einen Klick entfernt!',
      active: clickedShowIntercom,
      content:
        'Klicke einfach auf den Button am rechten unteren Bildschirmrand, um direkt Kontakt zu unserem Physiofit-Team aufzunehmen. Wir sind da, um deine Fragen zu beantworten und dich bei deinem Start mit Physiofit bestmöglich zu unterstützen. Hier findest du auch häufig gestellte Fragen anderer Nutzer und Hilfe-Artikel zu allen relevanten Themen rund um Physiofit.',
      mainButton: {
        title: 'Ok, verstanden',
        onClick: toggleIntercom,
      },
      variant: 'button',
    },
    ...addTherapistsElements,
  ];
  const finishedSteps = elements.filter((element) => element.active);
  const openSteps = elements.filter((element) => !element.active);

  return (
    <MainScreenContainer>
      <Container>
        <StyledH1>Dein Physiofit Onboarding</StyledH1>
        {finishedSteps.length > 0 && <StyledH4>Abgeschlossene Schritte</StyledH4>}
        {finishedSteps.map((element, index) => (
          <StepCard key={index + element.title} {...element} />
        ))}
        <StyledH4>Deine nächsten Schritte</StyledH4>
        {openSteps.map((element, index) => (
          <StepCard key={index + element.title} {...element} />
        ))}
        <ComingSoonCard />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 200px;
`;

const StyledH1 = styled(H1)``;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
  margin-top: 40px;
`;
