import { deleteField, doc, setDoc, updateDoc } from 'firebase/firestore';
import posthog from 'posthog-js';
import { db } from '../../../firebaseConfig';
import { getChainData } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId } from '../../../store/userSlice';
import { OnboardingFlags } from '../../../types/Practices/ChainType';

export const editChain = async (newData: { [key: string]: any }) => {
  const state = useMainStore.getState();
  const chainId = getUserChainId(state);
  await updateDoc(doc(db, 'practiceChains/' + chainId), newData);
};

export type Leaves<T> = T extends object
  ? { [K in keyof T]-?: `${Exclude<K, symbol>}${Leaves<T[K]> extends never ? '' : `.${Leaves<T[K]>}`}` }[keyof T]
  : never;

export const setChainOnboardingFlag = async (
  key: Leaves<OnboardingFlags> | Leaves<OnboardingFlags>[],
  posthogKey?: string,
) => {
  let changes: { [key: string]: any } = {};
  if (Array.isArray(key)) {
    key.forEach((k) => (changes['onboarding.onboardingFlags.' + k] = true));
  } else {
    changes['onboarding.onboardingFlags.' + key] = true;
  }
  posthog.capture('onboarding_step_' + posthogKey + '_completed');
  await editChain(changes);
};

export const removePracticeFromOnboardingFlags = async (practiceId?: string) => {
  await editChain({ ['onboarding.onboardingFlags.practices.' + practiceId]: deleteField() });
};

export const addPracticesToChain = async (practices: { id: string; name: string }[]) => {
  const flags = practices.reduce(
    (acc, practice, index) => {
      acc[`onboarding.onboardingFlags.practices.${practice.id}.index`] = index;
      return acc;
    },
    {} as { [key: string]: any },
  );
  await editChain({ practices, ...flags });
};

export const createNewPracticesFirestore = async (practices: { name: string; id: string }[]) => {
  const state = useMainStore.getState();
  const chainData = getChainData(state);
  const chainHubspotId = chainData?.hubspot?.chainId;
  const initialOwnerId = chainData?.hubspot?.initialOwnerId;
  for (const practice of practices) {
    await setDoc(
      doc(db, 'practices/' + practice.id),
      {
        ownerId: chainData?.ownerId,
        createdAt: new Date().toISOString(),
        name: practice.name,
        id: practice.id,
        chainId: chainData?.id,
        displayName: practice.name,
        smsName: practice.name,
        hubspot: { chainId: chainHubspotId, initialOwnerId },
      },
      { merge: true },
    );
  }
};
export const removePracticeFromChain = async (practiceId: string, practices: { id: string; name: string }[]) => {
  const newPractices = practices.filter((e) => e.id !== practiceId);
  const flags = newPractices.reduce(
    (acc, practice, index) => {
      acc[`onboarding.onboardingFlags.practices.${practice.id}.index`] = index;
      return acc;
    },
    {} as { [key: string]: any },
  );
  await editChain({
    practices: newPractices,
    ...flags,
    ['onboarding.onboardingFlags.practices.' + practiceId]: deleteField(),
  });
};
