import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { ButtonText } from '../../../styles/textStyles';
import { hexWithOpacity } from '../../utils/hexWithOpacity';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CustomButton } from './CustomButton';

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  numberOfSteps?: number;
  currentStep?: number;
  loading?: boolean;
  primaryColor?: string;
};

export const ProgressButton = (props: Props) => {
  const disabled =
    props.disabled ||
    (props.numberOfSteps !== undefined && props.currentStep !== undefined && props.currentStep < props.numberOfSteps);
  const [progress, setProgress] = useState(!props.numberOfSteps ? 100 : 0);
  const [duration, setDuration] = useState(0);
  const prevProgressRef = useRef(0);

  useEffect(() => {
    if (!props.numberOfSteps || props.currentStep === undefined) return;
    const newProgress = (props.currentStep / props.numberOfSteps) * 100;
    const progressDifference = Math.abs(newProgress - prevProgressRef.current);
    const newDuration = Math.max(0.2, Math.min(progressDifference * 0.01, 1));

    setDuration(newDuration);
    setProgress(newProgress);
    prevProgressRef.current = newProgress;
  }, [props.currentStep, props.numberOfSteps]);

  return (
    <Background>
      <StyledCustomButton onClick={props.onClick} disabled={disabled || props.loading} color={props.primaryColor}>
        <ProgressFill $progress={progress} $duration={duration} $color={props.primaryColor} />
        {props.loading ? <LoadingSpinner /> : <StyledButtonText>{props.text}</StyledButtonText>}
      </StyledCustomButton>
    </Background>
  );
};

const Background = styled.div`
  border-radius: 100px;
  overflow: hidden;
`;

const StyledCustomButton = styled(CustomButton)<{ $color?: string }>`
  background-color: ${(props) => hexWithOpacity(props.color ?? colors.Primary, 0.2)};
  padding: 0 20px;
  border-radius: 100px;
  height: 50px;
  min-width: 200px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const ProgressFill = styled.div<{ $progress: number; $duration: number; $color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.$progress}%;
  background-color: ${(props) => props.$color ?? colors.Primary};
  transition: width ${(props) => props.$duration}s ease-in-out;

  &::after {
    content: '';
    display: ${(props) => (props.$progress === 0 ? 'none' : 'block')};
    position: absolute;
    top: 0;
    right: -8px;
    bottom: 0;
    width: 16px;
    background-color: ${(props) => props.$color ?? colors.Primary};
    border-radius: 50% 50% 50% 50%;
  }
`;

const StyledButtonText = styled(ButtonText)`
  color: ${colors.White};
  position: relative;
  z-index: 1;
`;
