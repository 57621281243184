import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { db } from '../../../firebaseConfig';

export const useRefreshOutdatedApp = () => {
  useEffect(() => {
    const subscription = onSnapshot(doc(db, 'appInfo/practiceDashboard'), (snapshot) => {
      const data = snapshot.data();
      console.log('firestoreVersion', data?.version);
      console.log('import.meta.env.VITE_VERSION', import.meta.env.VITE_VERSION);
      if (data?.version !== import.meta.env.VITE_VERSION) {
        alert('Neue Version verfügbar. Seite wird neu geladen.');
        console.log('Neue Version verfügbar. Seite wird neu geladen.');
        window.location.reload();
      }
    });
    return () => subscription();
  }, []);
};
