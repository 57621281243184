import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { H4 } from '../../../styles/textStyles';
import { DrawerNavItem } from './DrawerNavItem';

type Props = {
  headline: string;
  items: { title: string; path: string; children?: { title: string; path: string }[] }[];
  toggleDrawer: () => void;
};

export const DrawerCategory = ({ headline, items, toggleDrawer }: Props) => {
  const path = useLocation().pathname;

  return (
    <Category>
      <StyledH4>{headline}</StyledH4>
      <Items>
        {items.map((item, index) => {
          const extended = path.includes(item.path);
          return (
            <>
              <DrawerNavItem
                key={item.path + index}
                {...item}
                closeDrawer={toggleDrawer}
                isParent={item.children && item.children.length > 0}
              />
              {extended &&
                item.children?.map((child) => (
                  <Items key={child.path}>
                    <DrawerNavItem key={item.path} path={child.path} closeDrawer={toggleDrawer} title={child.title} />
                  </Items>
                ))}
            </>
          );
        })}
      </Items>
    </Category>
  );
};

const Category = styled.div``;

const StyledH4 = styled(H4)``;

const Items = styled.div`
  padding-left: 20px;
`;
