import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TherapeutenIPad from '../../../assets/images/TherapeutenIPad.jpg';
import WebAppInstallationAndroid from '../../../assets/images/WebAppInstallationAndroid.jpg';
import WebAppInstallationIOS from '../../../assets/images/WebAppInstallationIOS.jpg';
import { AccordionComponent } from '../../../core/components/AccordionComponent';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { TutorialSteps } from '../../../core/components/lists/TutorialSteps';
import { InfoText } from '../../../core/components/text/InfoText';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { H4, Small } from '../../../styles/textStyles';
import { MainScreenContainer } from '../components/MainScreenComponent';

export const WebAppInstallationInstructionScreen = () => {
  const practices = useMainStore(getChainPractices);
  const navigate = useNavigate();
  const openLink = (id: string) => {
    const practiceDomain =
      import.meta.env.VITE_ENV === 'production'
        ? 'https://app.physiofit.app'
        : 'https://io-physiofit-development.web.app';
    const practiceUrl = `${practiceDomain}/newPatient?pId=${id}`;
    window.open(practiceUrl, '_blank');
  };

  const handleClose = () => {
    navigate('../overview');
  };
  const LinkButton = () => {
    return (
      <LinkButtonContainer>
        <H4>{'Link zum Easyplan Konfigurator:'}</H4>
        <Small>{'(Für deine Standorte)'}</Small>
        {practices?.map((practice) => (
          <TextButton key={practice.id} text={practice.name} onClick={() => openLink(practice.id)} />
        ))}
      </LinkButtonContainer>
    );
  };
  return (
    <MainScreenContainer onCloseClick={handleClose}>
      <Container>
        <HeaderImage src={TherapeutenIPad} alt="TherapeutenIPad" />
        <InfoText
          headline="Physiofit Therapeuten App Icon für eure mobile Praxis-Geräte"
          text={[
            'Den besten Zugang zu Physiofit, erhalten deine Therapeuten über ein App-Icon auf dem Homescreen eurer Praxis-Tablets und Smartphones. Wie das geht? Ganz einfach – wir haben eine kurze Video Anleitung, zum mitmachen, für euch aufgenommen. Die Video-Anleitung für Apple Geräte und alle andere Hersteller findet ihr hier:',
          ]}
        />
        <AccordionComponent title="Apple Geräte (iPad & iPhones)">
          <InfoText
            headline="So speichert ihr Physiofit auf euren Apple Geräten"
            text={[
              'Um jederzeit einfach und schnell Zugriff auf deinen EasyPlan Konfigurator zu erlangen, speichere einfach die Webseite des Konfigurators  auf dem Homescreen deines Tablets oder Smartphones. Öffne hierzu zunächst den Konfigurator.',
            ]}
          />

          <LinkButton />
          <TutorialSteps
            steps={[
              { text: 'Öffne den Physiofit EasyPlan Konfigurator in deinem Browser' },
              { text: 'Klicke in der Menüleiste deines Browsers auf dieses Symbol:', iconName: 'SafariShare' },
              {
                text: 'Scrolle in dem nun geöffneten Menü nach unten und klicke auf “Zum Home-Bildschirm”.',
                iconName: 'SafariPlus',
              },
              { text: 'Bestätige auf der darauf folgenden Seite das “Hinzufügen”.' },
            ]}
          />
          <ImageContainer>
            <IPhoneImage src={WebAppInstallationIOS} alt="WebAppInstallationIOS" />
          </ImageContainer>
        </AccordionComponent>

        <AccordionComponent title="Andere Hersteller (Tablets & Smartphones)">
          <InfoText
            headline="So speichert ihr Physiofit auf Android Geräten"
            text={[
              'Um jederzeit einfach und schnell Zugriff auf deinen EasyPlan Konfigurator zu erlangen, speichere einfach die Webseite des Konfigurators  auf dem Homescreen deines Tablets oder Smartphones. Öffne hierzu zunächst den Konfigurator.',
            ]}
          />
          <LinkButton />
          <TutorialSteps
            steps={[
              { text: 'Öffne den Physiofit EasyPlan Konfigurator in deinem Browser' },
              {
                text: 'Klicke im Browser rechts oben auf die drei Punkte oder rechts unten auf die 3 Striche (je nach Smartphone Modell).',
              },
              {
                text: 'Wähle die Option “Zum Startbildschirm hinzufügen” oder ähnlich und klicke danach auf “Hinzufügen”.',
              },
            ]}
          />
          <ImageContainer>
            <IPhoneImage src={WebAppInstallationAndroid} alt="WebAppInstallationAndroid" />
          </ImageContainer>
        </AccordionComponent>
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 200px;
`;

const HeaderImage = styled.img`
  width: 100%;
  max-height: 140px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const IPhoneImage = styled.img`
  height: 443px;
  border-radius: 10px;
`;

const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
`;
