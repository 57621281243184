import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CounterButton } from '../../../core/components/buttons/CounterButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import {
  addPracticesToChain,
  createNewPracticesFirestore,
  removePracticeFromChain,
  setChainOnboardingFlag,
} from '../../../core/utils/editFirestore/editChain';
import { getChainPractices, getOnboardingFlags } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { H4 } from '../../../styles/textStyles';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const OnboardingPracticeNamesURL = 'practices-names';

export const OnboardingPracticeNamesScreen = () => {
  const navigate = useNavigate();
  const initialPractices = useMainStore(getChainPractices) ?? [{ name: '', id: uuidv4() }];
  const { isAddNewPractice, isMigration } = useOnboardingPracticeInfo();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [practices, setPractices] = useState<{ name: string; id: string }[]>(
    isAddNewPractice ? [...initialPractices, { name: '', id: uuidv4() }] : initialPractices,
  );

  const onboardingFlags = useMainStore(getOnboardingFlags);
  const practiceFlags = onboardingFlags?.practices;
  const finishedPractices = Object.values(practiceFlags ?? {}).filter((e) => e.checkout).length;

  const changeLocationsAmount = (value: number) => {
    const newPractices = [...practices];
    if (value < finishedPractices || value < 1) {
      return;
    }
    if (value > practices.length) {
      newPractices.push({ name: '', id: uuidv4() });
    } else {
      const element = newPractices.pop();
      if (element) {
        removePracticeFromChain(element?.id, initialPractices);
      }
    }
    setPractices(newPractices);
  };
  const handleNext = async () => {
    if (practices.filter((p) => p.name === '').length > 0) return;
    setLoading(true);
    try {
      const newPractices = practices.filter((e) => !practiceFlags?.[e.id]?.completed);
      await addPracticesToChain(practices);
      await createNewPracticesFirestore(newPractices);
      await setChainOnboardingFlag('practiceNames', 'practiceNames');
      const firstPracticeId = newPractices?.[0].id;
      navigate(`../${firstPracticeId}/practice-data-info`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const changePracticeName = (index: number, value: string) => {
    const newPractices = [...practices];
    newPractices[index].name = value;
    setPractices(newPractices);
  };

  const handleBack = () => {
    navigate('../basic-info');
  };

  if (isMigration) {
    return <Navigate to="/onboarding/basic-info" replace={true} />;
  }

  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      hideBackButton={isAddNewPractice}
      nextButton={{
        onClick: handleNext,
        loading,
        numberOfSteps: practices.length,
        currentStep: practices.filter((e) => e.name !== '').length,
      }}
    >
      <InfoText
        headline={t('ONBOARDING-PRACTICE-NAMES-SCREEN-TITLE')}
        text={[t('ONBOARDING-PRACTICE-NAMES-SCREEN-SUBTITLE'), t('ONBOARDING-PRACTICE-NAMES-SCREEN-WARNING')]}
      />
      <NumberPracticeLocationsContainer>
        <H4>{t('ONBOARDING-PRACTICE-NAMES-SCREEN-AMOUNT-PRACTICES')}</H4>
        <CounterButton
          value={practices.length}
          setValue={changeLocationsAmount}
          minValue={isAddNewPractice ? finishedPractices + 1 : 1}
        />
      </NumberPracticeLocationsContainer>
      <LocationNamesContainer>
        {practices.map((practice, index) => (
          <SingleTextInput
            id={index === 0 ? 'firstInput' : ''}
            key={index}
            value={practice.name}
            onChange={(value) => changePracticeName(index, value)}
            placeholder={t('ONBOARDING-PRACTICE-NAMES-SCREEN-PRACTICE-NAME-PLACEHOLDER')}
            label="Standortname"
            disabled={practiceFlags?.[practice.id]?.checkout}
          />
        ))}
      </LocationNamesContainer>
    </OnboardingScreenContainer>
  );
};

const NumberPracticeLocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LocationNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
