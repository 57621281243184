import { useState } from 'react';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { FullScreenContainer } from '../../../../core/components/container/FullScreenContainer';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H1, H4 } from '../../../../styles/textStyles';
import { useAllPractices } from '../../connectUtils/useAdminAllPractices';
import { AdminPracticeStatusLine } from '../components/AdminPracticeStatusLine';
import { downloadLabelData } from '../utils/downloadPackageLabels';

export const AdminPracticesOverviewScreen = () => {
  const eventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const practices = useAllPractices();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAllLabels = async () => {
    setIsDownloading(true);
    try {
      await downloadLabelData(practices);
    } catch (error) {
      eventIndicator('error', 'Failed to download labels');
    } finally {
      eventIndicator('success', 'Downloaded labels');
      setIsDownloading(false);
    }
  };

  return (
    <FullScreenContainer>
      <Container>
        <MainButton onClick={downloadAllLabels} text="Download new package files" loading={isDownloading} />
        <StyledH1>Praxen Overview</StyledH1>
        <Headline>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Label erstellt</Date>
          <Date>1. Mail</Date>
          <Date>2. Mail</Date>
          <H4>Actions</H4>
        </Headline>
        {practices
          ?.filter((e) => !e.isMigrated)
          .map((practice) => <AdminPracticeStatusLine key={practice.id} practice={practice} />)}
        <Date>Airtable Praxen</Date>

        <Headline>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Label erstellt</Date>
          <Date>1. Mail</Date>
          <Date>2. Mail</Date>
          <H4>Actions</H4>
        </Headline>
        {practices
          ?.filter((e) => e.isMigrated)
          .map((practice) => <AdminPracticeStatusLine key={practice.id} practice={practice} />)}
      </Container>
    </FullScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledH1 = styled(H1)``;

const Headline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${colors.Light};
  align-items: center;
`;

const Date = styled(H4)`
  flex: 1;
  text-align: left;
`;

const Name = styled(H4)`
  flex: 2;
  text-align: left;
`;
