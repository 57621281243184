import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { ScreenContainer } from '../../../core/components/container/ScreenContainer';
import { VersionTag } from '../../../core/components/VersionTag';
import { Header } from '../../../navigation/components/Header';

export const AdminLayout = () => {
  const { update } = useIntercom();

  useEffect(() => {
    update({ hideDefaultLauncher: true });
    () => update({ hideDefaultLauncher: false });
  }, [update]);

  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
      <VersionTag />
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  overflow-x: hidden;
`;
