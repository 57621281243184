import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { H1 } from '../../../../styles/textStyles';
import { MainScreenContainer } from '../../../overview/components/MainScreenComponent';

export const AdminHomeScreen = () => {
  return (
    <MainScreenContainer>
      <Container>
        <StyledH1>Admin Tool</StyledH1>
        {/* <MainButton onClick={testLogin} text="Test" /> */}
      </Container>
      <Outlet />
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 200px;
`;

const StyledH1 = styled(H1)``;
