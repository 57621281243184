import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingSummaryScreen = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { practiceName, uploadAppBrandingData, practiceId } = useAppBrandingPracticeInfo();
  const navigate = useNavigate();

  const handleNext = async () => {
    setLoading(true);
    setEventIndicator('info', 'Daten werden gespeichert.');
    try {
      await uploadAppBrandingData();
      setEventIndicator('success', 'Daten wurden erfolgreich gespeichert.');
      navigate(`/practice-settings/${practiceId}/branding-settings`);
    } catch (error) {
      console.error(error);
      setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
    } finally {
      setLoading(false);
    }
  };
  const handleBack = () => {
    navigate('../intro-video');
  };

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Bestätigen',
        onClick: handleNext,
        loading: loading,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Zusammenfassung'}
          text={[
            'Herzlichen Glückwunsch zum erfolgreichen Abschluss des App-Branding-Prozesses! Du hast alle notwendigen Informationen eingegeben und kannst das Ergebnis nun direkt test (nicht auf kleinen Mobil Geräten verfügbar). Falls du zukünftig Änderungen vornehmen möchtest, kannst du diese jederzeit in den Einstellungen anpassen.',
            'Wenn du mit deinen Eingaben zufrieden bist, klicke auf „Bestätigen“.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
