import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { ButtonText, H4, Small } from '../../../styles/textStyles';

type PracticeLinkContainerProps = {
  loadingPractice: boolean;
  practiceQrCode: string;
  practiceLink: string;
  copyLink: () => void;
  openLink: () => void;
};

export const PracticeLinkContainer = ({
  loadingPractice,
  practiceQrCode,
  practiceLink,
  copyLink,
  openLink,
}: PracticeLinkContainerProps) => {
  const { isMobileWidth } = useWindowDimensions();
  return (
    <Container>
      {loadingPractice ? (
        <>
          <Skeleton width={120} height={120} />
          <div style={{ width: '100%' }}>
            <Skeleton height={20} count={2} />
          </div>
        </>
      ) : (
        <>
          <QRCodeContainer>
            <QRCode src={practiceQrCode} alt="QR Code" />
            <InnerContainer>
              <LinkContainer>
                <H4>Praxislink</H4>
                <StyledSmall onClick={openLink}>{practiceLink}</StyledSmall>
              </LinkContainer>
              <LinkButtonsContainer>
                {isMobileWidth ? (
                  <IconButton iconName={'Clipboard'} onClick={copyLink} stroke={colors.Dark} size={24} />
                ) : (
                  <>
                    <StyledButtonText onClick={copyLink}>Link kopieren</StyledButtonText>
                    <StyledButtonText onClick={openLink}>Link öffnen</StyledButtonText>
                  </>
                )}
              </LinkButtonsContainer>
            </InnerContainer>
          </QRCodeContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  text-decoration: underline;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 365px;
  cursor: pointer;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;

const QRCode = styled.img`
  width: 120px;
  height: 120px;
`;
