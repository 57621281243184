import { Autocomplete } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';
import { usePracticeContactConnect } from '../../../core/utils/connectFirestore/usePracticeContactConnect';
import { getAppBrandingAddress, getAppBrandingColors, getAppBrandingContact } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { AppCustomization } from '../../../types/Practices/AppCustomizationType';
import { PracticePackageInfo } from '../../../types/Practices/PracticePackageInfoType';
import { usePracticePackageConnect } from '../../admin/connectUtils/useAdminPracticePackageConnect';
import { generateMapsImage } from '../utils/generateMapsImage';
import { useGooglePlaces } from '../utils/useGooglePlaces';

type Props = {
  setLoading: (loading: boolean) => void;
  practiceId: string;
};

const getTextString = (address?: AppCustomization['address'], packageAddress?: PracticePackageInfo) => {
  `${address?.practiceName ?? ''} ${address?.street ?? ''} ${address?.houseNumber ?? ''} ${address?.zip ?? ''} ${address?.city ?? ''}`;
  if (address && Object.values(address).every((value) => value && value !== '')) {
    return `${address.practiceName} ${address.street} ${address.houseNumber} ${address.zip} ${address.city}`;
  } else if (packageAddress && Object.values(packageAddress).every((value) => value && value !== '')) {
    return `${packageAddress.practiceName} ${packageAddress.street} ${packageAddress.houseNumber} ${packageAddress.cityCode} ${packageAddress.city}`;
  } else {
    return '';
  }
};

export const AppBrandingAddressInput = ({ practiceId }: Props) => {
  const location = useLocation();
  const path = location.pathname;
  const { practiceContact } = usePracticeContactConnect(practiceId ?? '');
  const practicePackageInfo = usePracticePackageConnect(practiceId ?? '');
  const appBrandingAddress = useMainStore(getAppBrandingAddress);
  const appBrandingContact = useMainStore(getAppBrandingContact);
  const appBrandingColors = useMainStore(getAppBrandingColors);
  const setAddress = useMainStore((state) => state.setAppBrandingAddress);
  const setGoogleMapsInfo = useMainStore((state) => state.setAppBrandingMapsInfo);
  const setContact = useMainStore((state) => state.setAppBrandingContact);
  const setOpeningHours = useMainStore((state) => state.setAppBrandingOpeningHours);
  const { googleApiLoaded, autocompleteOptions, onLoad, onPlaceChanged } = useGooglePlaces();

  const [text, setText] = useState(getTextString(appBrandingAddress, practicePackageInfo));
  const [loadingTextField, setLoadingTextField] = useState(true);

  useEffect(() => {
    if (practicePackageInfo) {
      setText(getTextString(appBrandingAddress, practicePackageInfo));
    }
  }, [practicePackageInfo]);

  const handlePlaceChanged = () => {
    const googleData = onPlaceChanged();
    handleGoogleData(googleData);
  };

  const handleGoogleData = useCallback(
    (googleData: any) => {
      if (googleData) {
        console.log('googleData', googleData);
        const newAddressData = {
          ...googleData.address,
          owner: practiceContact?.firstName + ' ' + practiceContact?.lastName,
        };
        setAddress(newAddressData);
        setGoogleMapsInfo({
          googleMapsId: googleData.placeId,
          longitude: googleData.lat,
          latitude: googleData.lng,
          mapsStaticImageUrl: generateMapsImage(googleData.lat, googleData.lng, appBrandingColors.Primary),
        });
        setContact({
          phone: googleData.contact.phone ?? practiceContact?.phoneNumber,
          mail: practiceContact?.email,
          ...addValueIfExists('website', googleData.contact.website),
        });
        setText(getTextString(newAddressData));

        setOpeningHours(googleData.openingHours);
      }
    },
    [appBrandingColors.Primary],
  );

  const handleInput = (name: string, value: string) => {
    setContact({
      ...appBrandingContact,
      [name]: value,
    });
  };

  const handleOwner = (name: string) => {
    if (!appBrandingAddress) return;
    setAddress({
      ...appBrandingAddress,
      owner: name,
    });
  };

  // useEffect(() => {
  //   if (googleApiLoaded) {
  //     console.log("document.getElementById('firstInput')", document.getElementById('firstInput'));
  //     document.getElementById('firstInput')?.focus();
  //   }
  // }, [googleApiLoaded]);

  const handleAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
    onLoad(autocomplete);
    setTimeout(() => {
      setLoadingTextField(false);
      document.getElementById('firstInput')?.focus();
    }, 1000);
  };

  const isInSettings = path.includes('practice-settings');

  return (
    <Container>
      {googleApiLoaded && (
        <Autocomplete onLoad={handleAutocompleteLoad} onPlaceChanged={handlePlaceChanged} options={autocompleteOptions}>
          <SingleTextInput
            style={{ display: loadingTextField ? 'none' : 'block' }}
            label="Google Maps Suche"
            id={isInSettings ? '' : 'firstInput'}
            value={text}
            onChange={setText}
            placeholder={'Physiofit Musterstraße 1 12345 München'}
            noPasswordManager
            type="practiceName"
          />
        </Autocomplete>
      )}
      {(!googleApiLoaded || loadingTextField) && (
        <SkeletonWrapper>
          <Skeleton height={60} borderRadius={10} />
        </SkeletonWrapper>
      )}
      {appBrandingAddress?.street && (
        <HorizontalContainer>
          <SmallContainer>
            <SingleTextInput
              value={appBrandingContact?.phone ?? ''}
              onChange={(value) => handleInput('phone', value)}
              placeholder={'+49 (0) 123 456 789'}
              label="Telefon"
            />
          </SmallContainer>
          <LargeContainer>
            <SingleTextInput
              value={appBrandingContact?.mail ?? ''}
              onChange={(value) => handleInput('mail', value)}
              label="E-Mail"
              placeholder={'physio-fit@muster.de'}
            />
          </LargeContainer>
        </HorizontalContainer>
      )}
      {appBrandingAddress?.street && (
        <HorizontalContainer>
          <SingleTextInput
            value={appBrandingAddress?.owner ?? ''}
            onChange={handleOwner}
            placeholder={'Astrid Mustermann'}
            label="Kontakt Name"
          />
        </HorizontalContainer>
      )}
      {/* <MainButton onClick={resetData} text={t('RESET')} /> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: column;
    align-items: center;
  }
`;

const LargeContainer = styled.div`
  flex: 2;
`;

const SmallContainer = styled.div`
  flex: 1;
`;

const HorizontalContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    gap: 0px;
  }
`;

const SkeletonWrapper = styled.div`
  width: 100%;
  padding-bottom: 18px;
`;
