import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useHandleInformationForm } from '../utils/useHandleInformationForm';

import { useTranslation } from 'react-i18next';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useNavigate } from 'react-router-dom';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId } from '../../../store/userSlice';
import { PracticeContact } from '../../../types/Practices/PracticeContactType';
import { PracticeContactForm } from '../components/PracticeContactForm';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress';
import { useHandleOnboardingPracticeContact } from '../utils/useHandleTransferContactData';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const practiceContactDataLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  const practiceSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/practiceContact'));
  const practiceData = practiceSnapshot?.data() as undefined | PracticeContact;
  return practiceData ?? null;
};

export const OnboardingPracticeContactURL = 'practice-contact/:practiceId';

export const OnboardingPracticeContactScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const chainId = useMainStore(getUserChainId);
  const { practiceId, practiceName, practicesAmount, isMigration } = useOnboardingPracticeInfo();

  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 4;
  const [loading, setLoading] = useState(false);

  const { handleToggle, toggleActive, fields, setFields } = useHandleOnboardingPracticeContact(practicesAmount);
  const { handleInput } = useHandleInformationForm(fields, setFields, setCurrentStep);

  const handleNext = async () => {
    if (!practiceId || currentStep < totalSteps) return;
    setLoading(true);
    try {
      setDoc(
        doc(db, 'practices/' + practiceId + '/documents/practiceContact'),
        {
          practiceId,
          chainId,
          ...fields,
          onboardingCopy: toggleActive,
        },
        { merge: true },
      );
      await setChainOnboardingFlag(`practices.${practiceId}.contactPerson`, 'practices-contactPerson');
      navigate('../practice-starter');
    } catch (error) {
      console.error('Error writing document: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (isMigration) {
      navigate('../practice-payout-data');
    } else {
      navigate('../practice-therapists');
    }
  };

  useHandleOnSubmitKeyPress(handleNext);

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  }

  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        currentStep: currentStep,
        numberOfSteps: 4,
        onClick: handleNext,
        loading: loading,
      }}
    >
      <InfoText
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })}
        headline={t('ONBOARDING-PRACTICE-CONTACT-SCREEN-TITLE')}
        text={[t('ONBOARDING-PRACTICE-CONTACT-SCREEN-SUBTITLE')]}
      />
      <LabeledSwitchButton
        label={t('ONBOARDING-PRACTICE-CONTACT-TRANSFER-PERSONAL-DATA')}
        isChecked={toggleActive}
        setIsChecked={handleToggle}
      />
      <PracticeContactForm fields={fields} handleInput={handleInput} />
    </OnboardingScreenContainer>
  );
};
