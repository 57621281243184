import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';
import { TherapistLinks } from './TherapistLinks';

type TherapistsLinksContainerProps = {
  therapists: Therapist[];
  loadingTherapists: boolean;
  practiceId: string | undefined;
};

export const TherapistsLinksContainer = ({
  therapists,
  loadingTherapists,
  practiceId,
}: TherapistsLinksContainerProps) => {
  const { isMobileWidth } = useWindowDimensions();
  return (
    <>
      <HeadlineContainer>
        <StyledH3>Therapeuten Links</StyledH3>
        <StyledBody>
          {
            'Hier findest du die individuellen Links für deine Therapeuten. Damit können sie direkt loslegen und Patienten zu Physiofit einladen.'
          }
        </StyledBody>
      </HeadlineContainer>
      <TherapistsContainer $isMobile={isMobileWidth}>
        {loadingTherapists ? (
          <Skeleton height={50} count={3} />
        ) : (
          therapists?.map((therapist) => (
            <TherapistLinks key={therapist.id} practiceId={practiceId ?? ''} therapist={therapist} />
          ))
        )}
      </TherapistsContainer>
    </>
  );
};

const TherapistsContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$isMobile ? '40px' : '20px')};
`;

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  padding-bottom: 15px;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;
