import { useEffect } from 'react';
import { enforceNumeric } from '../../../core/utils/phoneNumberRegex';

export const useHandleInformationForm = (
  fields: { [key: string]: any },
  setFields: (value: any) => void,
  setCurrentStep?: ((value: number) => void) | undefined,
  optionalFieldKeys?: string[],
) => {
  const handleInput = (field: string, value: any) => {
    if (field === 'phoneNumber') {
      value = enforceNumeric(value);
    }
    const newFields = { ...fields, [field]: value };
    console.log(newFields);
    setFields(newFields);
  };

  const changeMultipleFields = (newFields: { [key: string]: any }) => {
    setFields({ ...fields, ...newFields });
  };

  useEffect(() => {
    if (!setCurrentStep) return;
    const currentStep = Object.values(fields).filter((value, index) => {
      if (optionalFieldKeys && optionalFieldKeys.includes(Object.keys(fields)[index])) {
        return false;
      }
      return value !== '';
    }).length;
    setCurrentStep(currentStep);
  }, [fields, setCurrentStep, optionalFieldKeys]);

  return { handleInput, changeMultipleFields };
};
