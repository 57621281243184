import { forwardRef } from 'react';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { getAppBrandingColors, getAppBrandingOpeningHours } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Mini, Small } from '../../../../styles/textStyles';
import { MockDayLine } from './MockTimeLine';

export const MockOpeningHoursCard = forwardRef<HTMLDivElement>((_, ref) => {
  const tage = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  const openingHours = useMainStore(getAppBrandingOpeningHours);
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <Card ref={ref}>
      <Headline $color={appColors.Dark}>{'Unsere Öffnungszeiten'}</Headline>
      <DaysContainer>
        {tage.map((tag, index) => (
          <Day key={index}>
            <DayText $color={appColors.Medium}>{tag}</DayText>
            <MockDayLine text={openingHours?.[index]?.text ?? ''} />
          </Day>
        ))}
      </DaysContainer>
    </Card>
  );
});

const Day = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DayText = styled(Mini)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => hexWithOpacity($color, 0.6)};
`;

const Headline = styled(Small)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  margin-bottom: 10px;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  overflow: hidden;
  gap: 10px;
  padding: 20px 15px;
`;

const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
