import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { Practice } from '../../../types/Practices/PracticeType';

export const usePracticeConnect = (practiceId?: string) => {
  const [practice, setPractice] = useState<Practice>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(doc(db, 'practices/' + practiceId), (practiceSnapshot) => {
      const practiceData = practiceSnapshot.data() as Practice;
      setPractice(practiceData);
    });
    return sub;
  }, [practiceId]);

  return {
    loadingPractice: practice === undefined,
    practice,
  };
};
