import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppBrandingPhoneMock } from '../features/appBranding/components/AppBrandingPhoneMock';
import { useHandleScroll } from '../features/onboarding/utils/useHandleScroll';
import { DESKTOP_BREAKPOINT } from '../styles/breakpoints';

export const PhoneMockLayout = () => {
  const { containerRef, childRef } = useHandleScroll();
  const location = useLocation();

  const path = location.pathname;

  let mockType: 'home' | 'practice' | 'video' | 'summary' = 'practice';
  if (path.includes('logo') || path.includes('color')) {
    mockType = 'home';
  } else if (path.includes('video')) {
    mockType = 'video';
  } else if (path.includes('summary')) {
    mockType = 'summary';
  }

  return (
    <Container ref={containerRef}>
      <Outlet />
      <StyledAppBrandingPhoneMock ref={childRef} className="child" type={mockType} />
    </Container>
  );
};

const StyledAppBrandingPhoneMock = styled(AppBrandingPhoneMock)`
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1050px;
  justify-content: 'flex-start';
  position: relative;
  background-color: 'blue';
  min-height: 800px;
  margin-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 20px;
    width: 100%;
    max-width: 955px;
    justify-content: center;
    min-height: auto;
  }
`;
